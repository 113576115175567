.payment-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px 0;
}

.loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
    color: #000;
}

  
  .card {
    border-radius: 0.75rem;
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1);
  }
  
  .card-raised {
    margin: -2rem 0 1rem;
    padding: 2rem;
    background: #fff;
  }
  
  .card-body {
    padding: 2rem;
  }
  
  .header {
    background-color: #343a40;
    color: white;
    padding: 1rem;
    text-align: center;
  }
  
  .header h1 {
    margin: 0;
    color: #fff;
  }
  
  .container-xl {
    padding: 20px;
  }
  
  .mwc-tab-bar {
    display: flex;
    position: relative;
  }
  
  .mwc-tab {
    padding: 10px 20px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease;
  }
  
  .mwc-tab.active {
    color: #fff;
    background-color: #007bff;
  }
  
  .active-tab-indicator {
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: #002366;
    transition: left 0.3s ease-in-out;
    width: calc(100% / 5);
  }



  