#layoutDrawer_content {
  width: 100%;
  max-width: 1400px;
  margin: auto;
  padding: 10px;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.card {
  margin-bottom: 10px;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.card-subtitle {
  font-size: 0.875rem;
  color: #6c757d;
}

.mwc-tab-bar {
  display: flex;
  margin-bottom: 20px;
  position: relative;
}

.mwc-tab {
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #f0f0f0;
  color: #333;
  transition: background-color 0.3s ease;
  margin-right: 10px;
  text-align: center;
  flex-grow: 1;
}

.mwc-tab.active {
  border-bottom: 2px solid #007bff;
  background-color: #007bff;
  color: #fff;
}

.mwc-tab:hover {
  background-color: #e0e0e0;
}

.form-control {
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: #007bff;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn:hover {
  color: #ffffff;
  background-color: #0056b3;
  border-color: #0056b3;
}

.text-end {
  text-align: end;
}

.img-fluid {
  max-width: 150px;
  max-height: 150px;
  width: auto;
  height: auto;
  display: block;
  margin: auto;
  border-radius: 50%;
}

.caption {
  font-size: 0.875rem;
  color: #6c757d;
  margin-bottom: 1rem;
}

.row {
  margin-bottom: 1rem;
}

.form-floating-sm {
  margin-bottom: 1rem;
}

@media (max-width: 992px) {
  .col-xl-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
 
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  width: 150px;
}

.account-subscription-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
}

.card-body {
  padding: 20px;
}

.header {
  background: #343a40;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.header h1 {
  margin: 0;
  color: #fff;
}

.container-xl {
  padding: 20px;
}

.mwc-tab-bar {
  display: flex;
  position: relative;
}

.mwc-tab {
  padding: 10px 20px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
}

.mwc-tab.active {
  color: #fff;
  background-color: #007bff;
}

.active-tab-indicator {
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: #002366;
  transition: left 0.3s ease-in-out;
  width: calc(100% / 5);
}

.col-md-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

@media (max-width: 768px) {
  .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
