/* Admindashboard.css */

.dashboard-container {
  padding: 20px;
}

.card-row {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 20px;
  
}

.dashboard-card {
  flex: 1;
  margin: 10px;
  padding: 20px;
  min-width: 200px;
}
.total-vehicles-card {
  /* border: 2px solid #131313; Yellow border */
  box-shadow: 0 4px 8px rgba(248, 180, 0, 0.2);
}

.active-vehicles-card {
  /* border: 2px solid #2b9eb3; Blue border */
  box-shadow: 0 4px 8px rgba(43, 158, 179, 0.2);
}

.stopped-vehicles-card {
  /* border: 2px solid #f85f73; Pink border */
  box-shadow: 0 4px 8px rgba(248, 95, 115, 0.2);
}

.chart-container {
  margin-top: 20px;
}

.filter-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.chart-card {
  padding: 20px;
  min-height: 400px;
}

@media (max-width: 768px) {
  .filter-container {
    flex-direction: column;
  }
  .dashboard-card, .chart-card {
    margin: 10px 0;
  }
}
