/* OTPValidation.css */

body, html {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .bg-primary {
    background-color: #f8f9fa; /* Light gray background for contrast */
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #layoutAuthentication {
    width: 100%;
    max-width: 800px; 
  }
  
  #layoutAuthentication_content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    padding: 2rem;
    width: 100%;
    max-width: 600px; /* Increase the max-width to make the card bigger */
    margin: auto;
  }
  
  .card .card-body {
    padding: 0;
  }
  
  .card .card-body .form-control {
    width: 95%;
    padding: 0.75rem ; /* Increase padding for larger input */
    margin-bottom: 1rem;
    border: 1.5px solid #ced4da;
    border-radius: 4px;
  }
  
  .card .card-body .form-control:focus {
    border-color: #80bdff;
    outline: 0;
  }
  
  .card .card-body .btn {
    width: 48%;
    padding: 0.75rem; /* Increase padding for larger buttons */
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .card .card-body .btn-primary {
    background-color: #007bff;
    color: #fff;
  }
  
  .card .card-body .btn-secondary {
    background-color: #6c757d;
    color: #fff;
  }
  
  .card .card-body .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .card .card-body .btn-secondary:hover {
    background-color: #545b62;
  }
  
  .card .card-body .d-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .card .card-body .text-center {
    margin-bottom: 1.5rem; /* Increase margin for more space between elements */
  }
  
  .card .card-body .text-center h1 {
    font-size: 2rem; /* Increase font size for the title */
    margin-bottom: 0.5rem;
  }
  
  .card .card-body .text-center p {
    margin-bottom: 1.5rem;
    color: #6c757d;
  }
  
  .loading {
    position: absolute;
    top: 0;
    right: 0;
    left: 0; 
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 1.5em;
    color: #007bff;
  }
  