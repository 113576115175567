body {
  margin: 0;
  font-family: Arial, sans-serif;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.notification-item {
  width: 450px; /* Set a fixed width for consistency */
  min-height: 60px; /* Ensure a consistent minimum height */
  border: 1px solid #ddd; /* Border around the card */
  border-radius: 4px; /* Rounded corners */
  padding: 10px; /* Internal padding */
  margin-bottom: 10px; /* Space between cards */
  background-color: #fff; /* Card background color */
  box-shadow: none; /* Remove shadow to prevent movement */
  transition: none; /* Disable transition effects */
  display: flex; /* Flexbox to align items */
  align-items: center; /* Center items vertically */
  position: relative; /* For hover effects */
}

.status-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.status-dot.read {
  background-color: green;
}

.status-dot.unread {
  background-color: red;
}


.main-layout {
  display: flex;
  width: 100%;
  border: none; /* Remove border */
  background-color: #f0f0f0; /* Change background color */
}


.app-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  height: 70px;
  transition: left 0.3s ease;
}

.app-navbar.full-width {
  left: 80px;
}

.app-navbar-items {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.tooltip {
  z-index: 9999;
}

.app-navbar-item {
  margin-left: 25px;
  position: relative;
}

.app-navbar-icon {
  font-size: 25px;
  cursor: pointer;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.main-content {
  margin-top: 70px;
  padding: 20px;
  flex-grow: 1;
  transition: margin-left 0.3s ease;
}

.main-content.with-sidebar {
  margin-left: 250px;
}

.main-content.full-width {
  margin-left: 80px;
}

.dropdown-menu {
  position: absolute;
  top: 60px; /* Adjust the top value to position it correctly */
  right: 0;
  z-index: 1000;
  display: block;
}

.dropdown-menu a {
  display: block;
  padding: 8px 12px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s;
}

.dropdown-menu a:hover {
  background-color: #f0f0f0;
}

.dropdown-toggle::after {
  vertical-align: middle;
  margin-left: 5px;
}


/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .app-navbar {
    left: 0;
    right: 0;
    padding: 0 10px;
  }

  .app-navbar-items {
    flex-direction: row-reverse;
    margin-left: 0;
  }

  .app-navbar-item {
    margin-left: 15px;
  }

  .app-navbar.full-width {
    left: 0;
  }

  .main-content.with-sidebar {
    margin-left: 0;
  }

  .main-content.full-width {
    margin-left: 0;
  }

  .user-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
}

.app-navbar-item .search-input {
  width: 0;
  transition: width 0.3s ease-in-out;
  display: inline-block;
  vertical-align: middle;
  border: none;
  background: white;
  outline: none;
}

.app-navbar-item .search-input:focus {
  width: 200px; /* Adjust as necessary */
  background: white;
  border: 1px solid #ccc;
}






