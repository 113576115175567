body {
  display: flex;
  margin: 0;
  font-family: Arial, sans-serif;
}

.sidebar {
  width: 270px;
  height: 100vh;
  background-color: #474141;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: width 0.3s ease;
  color: #fff;
  font-size: 15px;
}

.sidebar.closed {
  width: 80px;
}

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #333;
}

.sidebar-header img {
  width: 80%;
}

.toggle-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
}

.sidebar-nav {
  list-style-type: none;
  padding: 0;
}

.nav-item {
  margin: 10px 0;
  list-style-type: none;
}

.nav-item a {
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.nav-item a:hover {
  background-color: #555;
}

.nav-item .icon {
  font-size: 20px;
  margin-right: 10px;
  color: #fff;
}

.nav-section {
  margin: 20px 0 10px;
  color: #bbb;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.sidebar.closed .nav-item .icon {
  margin-right: 0;
}

.sidebar.closed .nav-item span {
  display: none;
}

.sidebar.closed .sidebar-header img {
  display: none;
}

.dropdown {
  list-style-type: disc;
  padding: 0;
  margin: 0;
  padding-left: 40px;
}

.dropdown li {
  margin: 5px 0;
}

.dropdown li a {
  text-decoration: none;
  color: #fff;
  display: block;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.dropdown li a:hover {
  background-color: #555;
}

.dropdown-arrow {
  margin-left: auto; /* Align arrow to the right */
  margin-right: 10px; /* Space between arrow and text */
}

/* Main content styles */
.main-layout {
  display: flex;
  width: 100%;
}

.app-navbar {
  position: fixed;
  top: 0;
  left: 270px;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  height: 70px;
  transition: left 0.3s ease;
}

.app-navbar.full-width {
  left: 80px;
}

.app-navbar-items {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.tooltip {
  z-index: 9999;
}

.app-navbar-item {
  margin-left: 25px;
  position: relative;
}

.app-navbar-icon {
  font-size: 25px;
  cursor: pointer;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.main-content {
  margin-top: 70px;
  padding: 20px;
  flex-grow: 1;
  transition: margin-left 0.3s ease;
}

.main-content.with-sidebar {
  margin-left: 270px;
}

.main-content.full-width {
  margin-left: 80px;
}

.dropdown-menu {
  margin-top: 16px;
  min-width: 140px;
  border-radius: 6px;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0;
  background-color: #fff;
  z-index: 10000;
}

.dropdown-menu a {
  display: block;
  padding: 8px 12px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s;
}

.dropdown-menu a:hover {
  background-color: #f0f0f0;
}

.dropdown-toggle::after {
  vertical-align: middle;
  margin-left: 5px;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 992px) {
  .sidebar {
    width: 200px;
  }

  .sidebar.closed {
    width: 80px;
  }
}

@media screen and (max-width: 768px) {
  .sidebar {
    width: 80px;
  }

  .sidebar.closed {
    width: 60px;
  }

  .sidebar-header img {
    width: 60%;
  }

  .toggle-btn {
    font-size: 16px;
  }

  .nav-item a {
    padding: 8px;
    font-size: 14px;
  }

  .nav-item .icon {
    font-size: 18px;
  }

  .nav-section {
    font-size: 8px;
  }

  .dropdown {
    padding-left: 30px;
  }

  .app-navbar {
    left: 80px;
  }

  .app-navbar.full-width {
    left: 60px;
  }

  .main-content.with-sidebar {
    margin-left: 80px;
  }

  .main-content.full-width {
    margin-left: 60px;
  }
}


