/* AccountSetting.css */

/* Ensure that the form is full-width on small screens */
@media (max-width: 576px) {
    .container {
        padding: 0 15px;
    }
    .card {
        margin: 0 -15px;
    }
}



/* Make sure the modal and buttons are responsive */
.modal-footer .btn {
    width: 100%;
    margin-top: 10px;
}

.modal-body {
    text-align: center;
}

/* Loader.css */

.loading-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.dot-loader {
    display: flex;
    gap: 5px;
}

.dot-loader span {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #007bff;
    border-radius: 50%;
    animation: dotLoader 1.2s infinite ease-in-out;
}

.dot-loader span:nth-child(2) {
    animation-delay: -1.1s;
}

.dot-loader span:nth-child(3) {
    animation-delay: -1.0s;
}

@keyframes dotLoader {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}

