/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* Status Dot Styles */
.status-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;
}

.status-dot.read {
  background-color: #4caf50; /* Green for read notifications */
}

.status-dot.unread {
  background-color: #f44336; /* Red for unread notifications */
}

/* Optional: Highlight clicked row */
.notification-row.clicked {
  background-color: #e0e0e0; /* Optional: highlight clicked row */
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}


#layoutDrawer_content {
  padding: 20px;
}

.notification-card {
  margin: 20px 0;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.notification-row.clicked {
  background-color: #e0e0e0; /* Optional: highlight clicked row */
}


/* Table Styles */
.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  padding: 12px;
  border: 1px solid #ddd;
}

.table th {
  background-color: #f4f4f4;
  text-align: left;
}

.table tbody tr {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.table tbody tr:hover {
  background-color: #f1f1f1;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the modal is on top */
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  box-sizing: border-box;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-body {
  margin-top: 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .container-xl {
    padding: 10px;
  }

  .notification-card {
    margin: 10px 0;
  }

  .table th, .table td {
    padding: 8px;
  }

  .modal {
    width: 90%;
    padding: 15px;
  }

  .modal-header h2 {
    font-size: 18px;
  }

  .close-button {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .container-xl {
    padding: 5px;
  }

  .notification-card {
    margin: 5px 0;
  }

  .table th, .table td {
    padding: 6px;
    font-size: 14px;
  }

  .modal {
    width: 95%;
    padding: 10px;
  }

  .modal-header h2 {
    font-size: 16px;
  }

  .close-button {
    font-size: 18px;
  }
}

/* Notification Row Styles */
.bg-light-basic {
  background-color: #f8f9fa; /* Light gray for read notifications */
}

.bg-light-info {
  background-color: #ffffff; /* White for unread notifications */
}

/* Additional styles for clicked notification */
.clicked {
  background-color: #e9ecef; /* Slightly different gray for clicked */
}
