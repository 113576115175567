.device-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 2px;
}

.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  margin: 20px;
  overflow: hidden;
  transition: transform 0.2s;
}

.card:hover {
  transform: translateY(-10px);
}

.card-content {
  padding: 15px;
}

.card-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.black-border {
  border: 1px solid black;
}

.header-cell {
  background-color: #3f51b5;
  color: #fff;
}
