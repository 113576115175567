/* Responsive styling for the BarChart component */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 20px;
  }
  
  .chart-container {
    width: 100%;
    max-width: 800px;
    height: auto;
    background-color: white;
    padding: 10px;
    
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(19, 18, 18, 0);
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2px;
    width: 80%;
  }
  
  @media (min-width: 768px) {
    .grid-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 1024px) {
    .grid-container {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  button {
    width: 20%;
  }
  
  canvas {
    width: 100% !important;
    height: auto !important;
  }
  