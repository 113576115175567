.container {
    background-color: #f0f0f0; /* Light gray background */
    padding: 10px;
}


.modal-content {
    background-color:#555; /* Changed background color */
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    max-width: 600px;
    margin: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.image-selection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.image-selection img {
    margin: 10px;
    border: 2px solid transparent;
}

.image-selection img.selected {
    border-color: #f7f8fa;
}

@media (max-width: 600px) {
    .modal-content {
        width: 90%;
        padding: 10px;
    }

    .image-selection img {
        width: 70px;
        height: 70px;
    }
}
