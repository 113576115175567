.heading {
    margin-bottom: 2rem;
    font-size: 1.5rem;
  }
  
  .pagination {
    margin-top: 2rem;
    display: flex;
    justify-content: center; /* Center pagination on smaller screens */
  }
  
  .pageNumbers {
    display: flex;
    padding: 0 1rem;
    flex-wrap: wrap; /* Allow wrapping of page numbers on smaller screens */
  }
  
  .num {
    width: 2rem;
    height: 2rem;
    display: grid;
    place-items: center;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    margin: 0.5rem; /* Add margin for spacing */
  }
  
  .num:hover,
  .numActive {
    font-weight: 600;
  }
  
  .numActive {
    background-color: #1cab94;
    color: white;
  }
  
  .arrows:hover {
    outline: none;
  }
  
  .arrows {
    cursor: pointer;
    user-select: none;
    display: grid;
    margin: 0.5rem; /* Add margin for spacing */
  }
  
  .arrows:active {
    outline: none; /* Remove the outline when active (clicked) */
  }
  
  /* Responsive styles */
  @media (max-width: 1200px) {
    .num {
      width: 1.75rem;
      height: 1.75rem;
      left: 10; /* Reset position */
    }
  
    .arrows {
      left: 0; /* Reset position */
    }
  }
  
  @media (max-width: 768px) {
    .num {
      width: 1.5rem;
      height: 1.5rem;
      left: 0; /* Reset position */
    }
  
    .arrows {
      left: 0; /* Reset position */
    }
  }
  
  @media (max-width: 480px) {
    .num {
      width: 1.5rem;
      height: 1.5rem;
      left: 10; /* Reset position */
    }
  
    .arrows {
      left: 0; /* Reset position */
    }
  
    .pagination {
      flex-direction: row; /* Stack pagination vertically */
      align-items: center;
    }
  
    .pageNumbers {
      justify-content: center; /* Center page numbers */
    }
  }
  