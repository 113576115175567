
.app-container {
  display: flex;
}
 
.main-content {
  flex: 1;
 
 
}
 
.main-content.shifted {
  margin-left: 270px;
}
 
@media screen and (max-width: 992px) {
  .main-content.shifted {
    margin-left: 200px;
  }
}
 
@media screen and (max-width: 768px) {
  .main-content.shifted {
    margin-left: 80px;
  }
}
 