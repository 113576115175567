/* Loader.css */

.loading-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8); /* Optional: Add a semi-transparent background */
}

/* Circle Loader */
.circle-loader {
    position: relative;
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #007bff; /* Blue */
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
