/* src/Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: white; /* White background for the navbar */
    border-bottom: 1px solid #ddd; /* Optional border at the bottom */
  }
  
  .navbar-title {
    font-size: 24px;
    font-weight: bold;
  }
  
  .login-button {
    font-size: 16px;
    color: white; /* Font color for the button text */
    background-color:  #007bff; /* Background color for the button */
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px; /* Optional rounded corners */
  }
  
  .login-button:hover {
    background-color: #7bb6f5; /* Optional hover effect */
}
  